import React, { useState, useEffect } from 'react'
import { Link, useParams,useNavigate } from 'react-router-dom';
import moment from 'moment';
import Sidebar from './Sidebar';
import { apiurl } from "../urls/url";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { jwtDecode } from "jwt-decode";

function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const [notificationStats, setNotificationStats] = useState([]);
  const [seenNotification, setSeenNotifications] = useState([]);
  const [user, setUser] = useState({});

  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
        try {
            const decoded = jwtDecode(token); 
            setUser(decoded)
            if (decoded?.vendor_id === 0) {
                navigate('/'); 
            }
        } catch (error) {
            navigate('/login');
        }
    } else {
        navigate('/login');
    }
}, [navigate]);


  const fetchUnseenData = async () => {
    try {
      const response = await axios.get(`${apiurl}/vendor-notifications-unseen/${user.vendor_id}/`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`, // Use the cleaned token
        }
    }).catch(
      (error) => {
        if(error.response){
          if(error.response.status===401){
            localStorage.removeItem("token");
            navigate('/login');
          }
        }
      }
    );
      setNotifications(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchSeenData = async () => {
    try {
      const response = await axios.get(`${apiurl}/vendor-notifications-seen/${user.vendor_id}/`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`, // Use the cleaned token
        }
    }).catch(
      (error) => {
        if(error.response){
          if(error.response.status===401){
            localStorage.removeItem("token");
            navigate('/login');
          }
        }
      }
    );
      setSeenNotifications(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchStatsData = async () => {
    try {
      const response = await axios.get(`${apiurl}/vendor-notifications-summary/${user.vendor_id}/`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`, // Use the cleaned token
        }
    }).catch(
      (error) => {
        if(error.response){
          if(error.response.status===401){
            localStorage.removeItem("token");
            navigate('/login');
          }
        }
      }
    );
      setNotificationStats(response.data[0]);
    } catch (error) {
      console.error('Error fetching stats data:', error);
    }
  };

  useEffect(() => {
    if(user.vendor_id){
      fetchUnseenData();
    }
  }, [user]);

  useEffect(() => {
    if(user.vendor_id){
      fetchSeenData();
    }
  }, [user]);

  useEffect(() => {
    if(user.vendor_id){
    fetchStatsData();
    }
  }, [user]);

  const handleNotificationSeenStatus = async (notiId) => {
    try {
      const response = await axios.get(`${apiurl}/vendor-notifications-mark-as-seen/${user.vendor_id}/${notiId}/`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`, // Use the cleaned token
        }
    }).catch(
      (error) => {
        if(error.response){
          if(error.response.status===401){
            localStorage.removeItem("token");
            navigate('/login');
          }
        }
      }
    );
      // console.log(response.data);
      await fetchStatsData();
      await fetchUnseenData();
      await fetchSeenData();
    } catch (error) {
      console.error('Error marking notification as seen:', error);
    }
  };


  return (
    <div className="container-fluid pt-5 bg-secondary" id="main" >
      <div className="row row-offcanvas row-offcanvas-left h-100">
        <Sidebar />
        <div className="col-md-9 col-lg-10 main mt-4">
          <h4 className="mt-3 mb-1"><i className="bi bi-bell-fill" /> Notifications</h4>
          <div className="dropdown">
          </div>
          <div className="col-md-12 col-lg-12 main mt-4">
            <div className="row mb-3">
              <div className="col-xl-4 col-lg-6 mb-2">
                <div className="card card-inverse card-success">
                  <div className="card-block bg-dark rounded p-3">
                    <h6 className="text-uppercase text-light w-100 text-center">  <i className="fas fa-eye-slash fa-1x" />&nbsp;Un-read Notification</h6>
                    <h1 className="display-1 text-light w-100 text-center">{notificationStats.un_read_noti}</h1>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 mb-2">
                <div className="card card-inverse card-success">
                  <div className="card-block bg-dark rounded p-3">
                    <h6 className="text-uppercase text-light w-100 text-center"><i className="fas fa-eye fa-1x" />&nbsp;Read Notification</h6>
                    <h1 className="display-1 text-light w-100 text-center">{notificationStats.read_noti}</h1>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 mb-2">
                <div className="card card-inverse card-success">
                  <div className="card-block bg-dark rounded p-3">
                    <h6 className="text-uppercase text-light w-100 text-center"><i className="fa-solid fa-bell fa-1x"></i>&nbsp;All Notification</h6>
                    <h1 className="display-1 text-light w-100 text-center">{notificationStats.all_noti}</h1>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row  container">
              <div className="col-lg-12">


                <table className="table">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">Type</th>
                      <th scope="col">Message</th>
                      <th scope="col">Status</th>
                      <th scope="col">Date</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {notifications?.map((noti, index) => (
                      <tr key={index}>
                        <td>
                          {noti.order !== null &&
                            <p>New Order {noti?.order?.oid}</p>
                          }
                        </td>
                        <td>
                          {noti.order_item !== null &&
                            <p>You've got a new order for <b>{noti?.order_item?.product?.title}</b></p>
                          }

                        </td>
                        <td>
                          {noti.seen === true
                            ? <p>Read <i className="fas fa-eye" /></p>
                            : <p>Unread <i className="fas fa-eye-slash" /></p>
                          }
                        </td>
                        <td>{moment(noti.date).format("MMM/DD/YYYY")}</td>
                        <td>
                          {noti.seen === true
                            ? <button disabled className="btn btn-success mb-1">
                              <i className="fas fa-check-circle" />
                            </button>

                            : <button onClick={() => handleNotificationSeenStatus(noti.id)} className="btn btn-dark mb-1">
                              <i className="fas fa-eye" />
                            </button>

                          }

                        </td>
                      </tr>
                    ))}

                    {notifications.length < 1 &&
                      <h4 className='mt-4 p-3'>No Notification Yet</h4>
                    }

                    <button type="button" className="btn btn-dark m-3" data-bs-toggle="modal" data-bs-target="#exampleModal">
                      View All Read Notifications
                    </button>

                    <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">All Read Notifications</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div className="modal-body">
                            <table className="table">
                              <thead className="table-dark">
                                <tr>
                                  <th scope="col">Type</th>
                                  <th scope="col">Message</th>
                                  <th scope="col">Status</th>
                                  <th scope="col">Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                {seenNotification?.map((noti, index) => (
                                  <tr key={index}>
                                    <td>
                                      {noti.order !== null &&
                                        <p>New Order {noti?.order?.oid}</p>
                                      }
                                    </td>
                                    <td>
                                      {noti.order_item !== null &&
                                        <p>You've got a new order for <b>{noti?.order_item?.product?.title}</b></p>
                                      }

                                    </td>
                                    <td>
                                      {noti.seen === true
                                        ? <p>Read <i className="fas fa-eye" /></p>
                                        : <p>Unread <i className="fas fa-eye-slash" /></p>
                                      }
                                    </td>
                                    <td>{moment(noti.date).format("MMM/DD/YYYY")}</td>

                                  </tr>
                                ))}

                                {seenNotification.length < 1 &&
                                  <h4 className='mt-4 p-3'>No Read Notification Yet</h4>
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>


      </div>


    </div >
  )
}

export default Notifications