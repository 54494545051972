import React, { useState, useEffect } from 'react'
import { Link, useParams,useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import { apiurl } from "../urls/url";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { jwtDecode } from "jwt-decode";

function Coupon() {
    const [user, setUser] = useState({});
    const [stats, setStats] = useState([])
    const [coupons, setCoupons] = useState([])
    const [createCoupons, setCreateCoupons] = useState({
        code: "",
        discount: "",
        active: true,
        offer_type:"",
        valid_from:"",
        valid_to:"",
        type:"",
        vendor:2,
        make_public:true
    })

    const navigate = useNavigate();
    useEffect(() => {
        const token = localStorage.getItem("token");
    
        if (token) {
            try {
                const decoded = jwtDecode(token); 
                setUser(decoded)
                if (decoded?.vendor_id === 0) {
                    navigate('/'); 
                }
            } catch (error) {
                navigate('/login');
            }
        } else {
            navigate('/login');
        }
    }, [navigate]);

    const fetchData = async () => {
        try {
            await axios.get(`${apiurl}/vendor-coupon-list/${user.vendor_id}/`,{
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`, // Use the cleaned token
                }
            }).then((res) => {
                setCoupons(res.data);
            }).catch(
                (error) => {
                  if(error.response){
                    if(error.response.status===401){
                      localStorage.removeItem("token");
                      navigate('/login');
                    }
                  }
                }
              )

            // await axios.get(`${apiurl}/vendor-coupon-list/1/`).then((res) => {
            //     setCoupons(res.data);
            // })

            await axios.get(`${apiurl}/vendor-coupon-stats/${user.vendor_id}/`,{
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`, // Use the cleaned token
                }
            }).then((res) => {
                setStats(res.data[0]);
            }).catch(
                (error) => {
                  if(error.response){
                    if(error.response.status===401){
                      localStorage.removeItem("token");
                      navigate('/login');
                    }
                  }
                }
              )
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if(user.vendor_id){
            fetchData();
        }
    }, [user]);

    const handleDeleteCoupon = async (couponId) => {
        await axios.delete(`${apiurl}/vendor-coupon-detail/${user.vendor_id}/${couponId}`,{
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`, // Use the cleaned token
            }
        }).then((res) => {
            console.log(res.data);
        }).catch(
            (error) => {
              if(error.response){
                if(error.response.status===401){
                  localStorage.removeItem("token");
                  navigate('/login');
                }
              }
            }
          )
        await fetchData();

    }

    const handleCreateCouponChange = (event) => {
        setCreateCoupons({
            ...createCoupons,
            [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
        })
        // console.log(createCoupons);
    }

    const handleCreateCoupon = async (e) => {
        e.preventDefault()
        const formdata = new FormData()

        // formdata.append("vendor_id", userData?.vendor_id)
        // formdata.append("vendor_id", 1)
        // formdata.append("code", createCoupons.code)
        // formdata.append("discount", createCoupons.discount)
        // formdata.append("active", createCoupons.active)

        await axios.post(`${apiurl}/vendor-coupon-create/${user.vendor_id}/`,createCoupons,{
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`, // Use the cleaned token
            }
        }).then((res) => {
            // console.log(res.data);
        }).catch(
            (error) => {
              if(error.response){
                if(error.response.status===401){
                  localStorage.removeItem("token");
                  navigate('/login');
                }
              }
            }
          )
        await fetchData();
        setCreateCoupons({
            code: "",
            discount: "",
            active: true,
            offer_type:"",
            valid_from:"",
            valid_to:"",
            type:"",
            vendor:2,
            make_public:true
        }
        )
    }

    return (
        <div className="container-fluid pt-5 bg-secondary" id="main" >
            <div className="row row-offcanvas row-offcanvas-left h-100">
                <Sidebar />
                <div className="col-md-9 col-lg-10 main mt-4">
                    <h4 className="mt-3 mb-4"><i className="bi bi-tag" /> Coupons</h4>
                    <button
                        type="button"
                        className="btn btn-dark mb-3"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                    >
                        <i className='fas fa-plus'></i> Create New Coupon
                    </button>
                    <div className="row mb-3">
                        <div className="col-xl-6 col-lg-6 mb-2">
                            <div className="card card-inverse card-dark">
                                <div className="card-block bg-dark rounded p-3 text-center">
                                    <div className="rotate">
                                        <i className="bi bi-tag fa-5x" />
                                    </div>
                                    <h6 className="text-uppercase text-light">Total Coupons</h6>
                                    <h1 className="display-1 text-light">{stats.total_coupons}</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 mb-2">
                            <div className="card card-inverse card-danger">
                                <div className="card-block bg-dark rounded p-3 text-center">
                                    <div className="rotate">
                                        <i className="bi bi-check-circle fa-5x" />
                                    </div>
                                    <h6 className="text-uppercase text-light">Active Coupons</h6>
                                    <h1 className="display-1 text-light">{stats.active_coupons}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row  container">
                        <div className="col-lg-12">
                            <table className="table">
                                <thead className="table-dark">
                                    <tr>
                                        <th scope="col">Code</th>
                                        <th scope="col">Type</th>
                                        <th scope="col">Discount</th>
                                        <th scope="col">Offer Type</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {coupons.map((coupon, index) => (
                                        <tr>
                                            <td>{coupon.code}</td>
                                            <td>{coupon.type}</td>
                                            <td>{coupon.discount}</td>
                                            <td>{coupon.offer_type}</td>
                                            <td>
                                                {coupon.active === true
                                                    ? <p>Active</p>
                                                    : <p>In-active</p>
                                                }
                                            </td>
                                            <td>

                                                <Link to={`/vendor/coupon/${coupon.id}/`} className="btn btn-dark mb-1">
                                                    <i className="fas fa-edit" />
                                                </Link>
                                                <button onClick={() => handleDeleteCoupon(coupon.id)} className="btn btn-dark mb-1 ms-2">
                                                    <i className="fas fa-trash" />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}

                                    {coupons < 1 &&
                                        <h5 className='mt-4 p-3'>No coupons yet</h5>
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>


            </div>
            <>
                {/* Button trigger modal */}

                {/* Modal */}
                <div
                    className="modal fade"
                    id="exampleModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Create New Coupon
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="modal-body">
                                <form onSubmit={handleCreateCoupon}>
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label">
                                            Code
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="exampleInputEmail1"
                                            aria-describedby="emailHelp"
                                            name='code'
                                            placeholder='Enter Coupon Code'
                                            onChange={handleCreateCouponChange}
                                            value={createCoupons.code}
                                        />
                                        <div id="emailHelp" className="form-text">
                                            E.g DESTINY2024
                                        </div>
                                    </div>
                                    <div className="mb-3 mt-4">
                                        <label htmlFor="start_from" className="form-label">
                                            Start From
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="start_from"
                                            name='valid_from'
                                            placeholder='Start Date'
                                            onChange={handleCreateCouponChange}
                                            value={createCoupons.valid_from}
                                        />
                                        <div id="start_from" className="form-text">
                                            NOTE: Optional
                                        </div>
                                    </div>
                                    <div className="mb-3 mt-4">
                                        <label htmlFor="valid_to" className="form-label">
                                            Valid To
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="valid_to"
                                            name='valid_to'
                                            placeholder='End Date'
                                            onChange={handleCreateCouponChange}
                                            value={createCoupons.valid_to}
                                        />
                                        <div id="valid_to" className="form-text">
                                            NOTE: Select Offer Type
                                        </div>
                                    </div>
                                    <div className="mb-3 mt-4">
                                        <label htmlFor="Type" className="form-label">
                                            Type
                                        </label>
                                        <select name="type" id="Type" className="form-control" value={createCoupons.type} onChange={handleCreateCouponChange}>
                                            <option value="">Select Type</option>
                                            <option value="Percentage">Percentage</option>
                                            <option value="Flat Rate">Flat Rate</option>
                                        </select>
                                        <div id="Type" className="form-text">
                                            NOTE: Select Type
                                        </div>
                                    </div>
                                    <div className="mb-3 mt-4">
                                        <label htmlFor="Discount_Type" className="form-label">
                                            Offer Type
                                        </label>
                                        <select name="offer_type" id="Discount_Type" className="form-control" value={createCoupons.offer_type} onChange={handleCreateCouponChange}>
                                            <option value="">Select Discount</option>
                                            <option value="First Order">First Order</option>
                                            <option value="Weekly Deal">Weekly Deal</option>
                                            <option value="Deal of the Hour">Deal of the Hour</option>
                                            <option value="Flash Sale">Flash Sale</option>
                                            <option value="Exit Intent">Exit Intent</option>
                                            <option value="Holiday Discount">Holiday Discount</option>
                                            <option value="Free Shipping">Free Shipping</option>
                                            <option value="Pre-Launch">Pre-Launch</option>
                                            <option value="Newsletter Signup">Newsletter Signup</option>
                                            <option value="Product Specific">Product Specific</option>
                                            <option value="Minimum Purchase">Minimum Purchase</option>
                                            <option value="Buy One Get One Free">Buy One Get One Free</option>
                                            <option value="Customer Referral">Customer Referral</option>
                                            <option value="Abandoned Cart">Abandoned Cart</option>
                                            <option value="Loyalty Based">Loyalty Based</option>
                                            <option value="In-Store">In-Store</option>
                                            <option value="Mobile App">Mobile App</option>
                                            <option value="Birthday">Birthday</option>
                                            <option value="Feedback Survey">Feedback Survey</option>
                                        </select>
                                        <div id="emailHelp" className="form-text">
                                            NOTE: Select Offer Type
                                        </div>
                                    </div>
                                    <div className="mb-3 mt-4">
                                        <label htmlFor="exampleInputPassword1" className="form-label">
                                            Discount
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="exampleInputPassword1"
                                            name='discount'
                                            placeholder='Enter Discount'
                                            onChange={handleCreateCouponChange}
                                            value={createCoupons.discount}
                                        />
                                        {/* <div id="emailHelp" className="form-text">
                                            NOTE: Discount is in <b>percentage </b>
                                        </div> */}
                                    </div>
                                    <div className="mb-3 form-check">
                                        <input checked={createCoupons.active} onChange={handleCreateCouponChange} name='active' type="checkbox" className="form-check-input" id="exampleCheck1" />
                                        <label className="form-check-label" htmlFor="exampleCheck1">
                                            Activate Coupon
                                        </label>
                                    </div>
                                    <div className="mb-3 form-check">
                                        <input checked={createCoupons.make_public} onChange={handleCreateCouponChange} name='make_public' type="checkbox" className="form-check-input" id="publicaly" />
                                        <label className="form-check-label" htmlFor="publicaly">
                                           Make Public
                                        </label>
                                    </div>
                                    <button type="submit" className="btn btn-success w-100" data-bs-dismiss="modal" aria-label="Close">
                                        Create Coupon <i className='fas fa-check-circle'></i>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        </div >


    )
}

export default Coupon